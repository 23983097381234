import { Link } from "react-router-dom"
import { emailIcon, mapIcon, phoneIcon } from "../../Images"

function ContactInfo() {
  return (
    <>
        <section className="mb-5 mt-3">
            <div className="container-lg wrapper">
                <div className="row my-4">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <div className="bg-white p-3 rounded-3 h-100 shadow_hover">
                            <img src={emailIcon} height={50} width={50} alt="" className="mb-2" />
                            <h3 className="fs-5 mb-1 text_ui_dark fw-semibold">Email Support</h3>
                            <p className="text_ui_dark fs_6 mb-1">Have questions or need assistance? Contact us via email for prompt replies and dedicated support for all your inquiries.</p>
                            <Link className="d-inline-flex align-items-center text_dark_primary text-decoration-none link_hoverable" to="mailto:info@dalfapak.com" target="_blank">
                                <span>info@dalfapak.com</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <div className="bg-white p-3 rounded-3 h-100 shadow_hover">
                            <img src={phoneIcon} height={50} width={50} alt="" className="mb-2" />
                            <h3 className="fs-5 mb-1 text_ui_dark fw-semibold">Customer Helpline</h3>
                            <p className="text_ui_dark fs_6 mb-1">Reach out to our customer helpline for immediate assistance. Our team is available around the clock to support you.</p>
                            <Link className="d-inline-flex align-items-center text_dark_primary text-decoration-none link_hoverable" to="mailto:info@dalfapak.com" target="_blank">
                                <span>+93 238616180</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <div className="bg-white p-3 rounded-3 h-100 shadow_hover">
                            <img src={mapIcon} height={50} width={50} alt="" className="mb-2" />
                            <h3 className="fs-5 mb-1 text_ui_dark fw-semibold">Visit Our Office</h3>
                            <p className="text_ui_dark fs_6 mb-1">Feel free to visit our office for direct assistance. Our team will be happy to guide and assist with your needs.</p>
                            <Link className="d-inline-flex align-items-center text_dark_primary text-decoration-none link_hoverable" to="mailto:info@dalfapak.com" target="_blank">
                                <span>123 Dalfa Avenue, Karachi, Pakistan</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default ContactInfo