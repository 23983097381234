import HeroSection from "../../components/HeroSection/HeroSection"
import { cattleShowBg } from "../../Images"
import CountdownSection from "./CountdownSection"
import ShowsList from "./ShowsList"
import './CattleShowPage.css'
import ShowHighlights from "./ShowHighlights"
import { Helmet } from "react-helmet"

function CattleShowPage() {
  return (
    <>
      <Helmet>
        <title>Cattle Shows - Dalfa Pak</title>
      </Helmet>
      <HeroSection subheading="Explore our" heading="Cattle shows" text="Join our cattle shows to explore a variety of animals and enjoy a fun-filled experience with your family!" image={cattleShowBg} />
      <CountdownSection />
      <ShowsList />
      <ShowHighlights />
    </>
  )
}
export default CattleShowPage