import './Home.css'
import Hero from "./Hero"
import Offers from "./Offers"
import Arrivals from './Arrivals'
import CattleShows from './CattleShows'
import Brands from './Brands'
import Gallery from './Gallery'
import MobileApp from './MobileApp'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <div>
      <Helmet>
        <title>Home - Dalfa Pak</title>
      </Helmet>
      <Hero />
      <Offers />
      <Arrivals />
      <Brands />
      <Gallery />
      <CattleShows />
      <MobileApp />
    </div>
  )
}
export default Home