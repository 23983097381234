import CattleShowCard from "../../components/CattleShowCard/CattleShowCard"
import { showsbanner1 } from "../../Images"

function ShowsList() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg wrapper">
                <h2 className="text-center fw-semibold text_ui_dark display-5">Our <span className="text_primary">Shows</span></h2>
                <div className="row my-3">
                    <div className="col-sm-6 col-12 my-3">
                        <CattleShowCard image={showsbanner1} title="Dalfa Cattle Show" season="Season 4" location="Badar Expo Solutions Karachi" status="upcoming" />
                    </div>
                    <div className="col-sm-6 col-12 my-3">
                        <CattleShowCard image={showsbanner1} title="Dalfa Cattle Show" season="Season 3" location="Badar Expo Solutions Karachi" status="past event" />
                    </div>
                    <div className="col-sm-6 col-12 my-3">
                        <CattleShowCard image={showsbanner1} title="Dalfa Cattle Show" season="Season 2" location="Badar Expo Solutions Karachi" status="past event" />
                    </div>
                    <div className="col-sm-6 col-12 my-3">
                        <CattleShowCard image={showsbanner1} title="Dalfa Cattle Show" season="Season 1" location="Badar Expo Solutions Karachi" status="past event" />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default ShowsList