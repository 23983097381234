import './GalleryImageCard.css'

function GalleryImageCard(props) {
  return (
    <>
        <div className="imageCard rounded-2 overflow-hidden">
            <img src={props.image} alt={props.alt} />
        </div>
    </>
  )
}
export default GalleryImageCard