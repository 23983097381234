import { cattleShowBg, cattleShowLogo } from "../../Images"
import CountdownTimer from "./Timer"

function CountdownSection() {
    const headerStyles = {
        background: `linear-gradient(180deg,  #fffffffa, #fffffffa), url(${cattleShowBg}) center fixed`,
        backgroundSize: 'cover',
    }
    return (
        <>
            <section className="countDownSection py-5" style={headerStyles}>
                <div className="container-lg wrapper text-center">
                    <img src={cattleShowLogo} width={100} height={100} alt="" />
                    <h2 className="text-center text_secondary fs_4 my-2 text-uppercase fw-bold">Season 4</h2>
                    <CountdownTimer targetDate="2024-12-31T23:59:59" />
                    <h2 className="text-center text_secondary fs-5 mt-4 fw-semibold">Get Ready! Event Coming Soon</h2>
                </div>
            </section>
        </>
    )
}
export default CountdownSection