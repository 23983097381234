import { Helmet } from "react-helmet"
import HeroSection from "../../components/HeroSection/HeroSection"
import { daflaSeason3 } from "../../Images"
import GalleryList from "./GalleryList"

function SeasonsPage() {
  return (
    <>
      <Helmet>
        <title>Cattle Show Season - Dalfa Pak</title>
      </Helmet>
      <HeroSection subheading="Dalfa Cattle Show" heading="Season 3" image={daflaSeason3} />
      <GalleryList />
    </>
  )
}
export default SeasonsPage