import { Link } from "react-router-dom"
import { showsbanner1 } from "../../Images"
import './BrandCards.css'

function BrandCards() {
  return (
    <>
        <Link to="/brands" className="brandsCard d-block text-decoration-none p-3 rounded-4 shadow_hover bg-white">
            <div className="d-flex align-items-center gap-2">
                <div className="brandsCardImage">
                    <img src={showsbanner1} alt="" />
                </div>
                <div>
                    <h3 className="fs_5 m-0 fw-semibold text_ui_dark">Al Bashir Cattle Farm</h3>
                    <p className="fs_7 text-dark-emphasis m-0"><span className="fw-medium text_ui_dark">Owner:</span> Usman Rafique</p>
                    <div className="d-flex align-items-center">
                        <i className="bx bxs-star ratingStar"></i>
                        <i className="bx bxs-star ratingStar"></i>
                        <i className="bx bxs-star ratingStar"></i>
                        <i className="bx bxs-star ratingStar"></i>
                        <i className="bx bxs-star ratingStar"></i>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center gap-2 justify-content-end">
                <div className="verifiedBadge">
                    <i className='bx bxs-check-shield'></i>
                    <span className="fw-medium">CoC Memeber</span>
                </div>
                <i className="bx bx-right-arrow-alt fs-5 text_primary"></i>
            </div>
        </Link>
    </>
  )
}
export default BrandCards