import { daflaSeason3 } from "../../Images"
import GalleryImageCard from "./GalleryImageCard"

function GalleryList() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg wrapper">
                <div className="row my-3">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <GalleryImageCard image={daflaSeason3} alt='dalfa cattle show season 3 image 1' />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default GalleryList