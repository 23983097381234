import { galleryimg1, galleryimg2, galleryimg3, galleryimg4, galleryimg5, galleryimg7 } from "../../Images"

function Gallery() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg wrapper">
                <h2 className="text-center fw-semibold text_ui_dark display-5">Our <span className="text_primary">Gallery</span></h2>
                <div className="row m-0">
                    <div className="col-lg-6 col-sm-6 col-12 px-2 my-2">
                        <div className="row">
                            <div className="col-lg-6 col-12 my-2">
                                <div className="galleryCard cardSmall">
                                    <img src={galleryimg1} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 my-2">
                                <div className="galleryCard cardSmall">
                                    <img src={galleryimg5} alt="" />
                                </div>
                            </div>
                            <div className="col-12 my-2">
                                <div className="galleryCard cardLarge">
                                    <img src={galleryimg7} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12 px-2 my-2">
                    <div className="row">
                            <div className="col-12 my-2">
                                <div className="galleryCard cardLarge">
                                    <img src={galleryimg2} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 my-2">
                                <div className="galleryCard cardSmall">
                                    <img src={galleryimg4} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 my-2">
                                <div className="galleryCard cardSmall">
                                    <img src={galleryimg3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default Gallery