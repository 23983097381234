import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CardDetails.css";
import { animalPic1, animalPic2, animalPic3, animalPic4, newArrival1 } from "../../Images";

function CardDetails() {
    // State to manage the current preview image
    const [previewImage, setPreviewImage] = useState(newArrival1);

    // Function to handle image click and preview
    const handleImagePreviewer = (image) => {
        setPreviewImage(image);
    };

    return (
        <>
            <section className="my-5">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 my-2">
                            <div className="ImageContainer">
                                {/* Main Preview Image */}
                                <div className="imagePreviewer">
                                    <img src={previewImage} alt="Selected Animal" />
                                </div>

                                {/* Image Thumbnails */}
                                <div className="imagePagination py-2">
                                    {[newArrival1, animalPic1, animalPic2, animalPic3, animalPic4].map((image, index) => (
                                        <div key={index} className={`imagePaginationBox ${previewImage === image ? "active" : ""}`} onClick={() => handleImagePreviewer(image)}>
                                            <img src={image} alt={`Animal Thumbnail ${index + 1}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12 my-2">
                            {/* Remaining content remains the same */}
                            <div className="d-flex align-items-start justify-content-between">
                                <div>
                                    <h2 className="fw-semibold fs-3 text_ui_dark m-0">Bhola</h2>
                                    <p className="text_ui_dark m-0">Danish Cattle</p>
                                </div>
                                <div>
                                    <div className="dropdown menuDropdown">
                                        <button className="btn btn-transparent d-inline-flex align-items-center justify-content-center border-0 p-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#000000">
                                                <path d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                                            </svg>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-start border-0 shadow">
                                            <li>
                                                <Link to={"/card-details"} className="dropdown-item fs_7 p-2 px-3">
                                                    Copy Link
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/card-details"} className="dropdown-item fs_7 p-2 px-3">
                                                    Share to Whatsapp
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/card-details"} className="dropdown-item fs_7 p-2 px-3">
                                                    Call to Farm
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3">
                                <Link to={"tel:0131031013"} className="btn_primary d-inline-flex align-items-center gap-1">
                                    <i className="bx bx-phone"></i>
                                    <span>Call for price</span>
                                </Link>
                            </div>
                            <hr />
                            <div className="my-3">
                                <p className="text_ui_dark m-0 fs_5 fw-semibold">Animal info</p>
                                <div className="my-2">
                                    <p className="fw-medium fs_6 m-0">
                                        Breed Name: <span className="fw-normal">Gulabi Mail</span>
                                    </p>
                                </div>
                                <div className="my-2">
                                    <p className="fw-medium fs_6 m-0">
                                        Age: <span className="fw-normal">2 years 8 months</span>
                                    </p>
                                </div>
                                <div className="my-2">
                                    <p className="fw-medium fs_6 m-0">
                                        Teeth: <span className="fw-normal">4 teeth</span>
                                    </p>
                                </div>
                                <div className="my-2">
                                    <p className="fw-medium fs_6 m-0">
                                        Weight: <span className="fw-normal">700 kg</span>
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="my-3">
                                <p className="text_ui_dark m-0 fs_5 fw-semibold">Description</p>
                                <p className="fs_6 m-0 fw-normal">
                                    Bhola is a strong and healthy Gulabi Mail cattle with a calm and friendly nature. It’s well-built and perfect for those looking for a reliable and valuable animal.
                                </p>
                            </div>
                            <div className="my-4">
                                <button className="btn_primary w-100 py-2 text-uppercase fw-medium"data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    Place a bid
                                </button>
                                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content shadow rounded-4">
                                            <div className="modal-header">
                                                <p className="fs-5 fw-medium modal-title">Place your bid</p>
                                                <button className="btn-close shadow-none" data-bs-dismiss="modal"></button>
                                            </div>
                                            <div className="modal-body">
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label text_ui_dark mb-1 fs_6">Enter Bid Amount</label>
                                                    <input type="email" class="form-control py-2 shadow-none focused fs_6" placeholder="PKR" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                                <div class="mb-3">
                                                    <label for="exampleInputEmail1" class="form-label text_ui_dark mb-1 fs_6">Add Comment</label>
                                                    <input type="email" class="form-control py-2 shadow-none focused fs_6" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                                <p className="fs_6 text_ui_dark">Your bid is not a payment. Purchase details will be arranged later with the seller.</p>
                                                <div className="my-2 mb-3">
                                                    <button className="btn_primary w-100 py-2">Place Bid</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CardDetails;
