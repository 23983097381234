import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, MainLayout, NotFound } from './Export';
import BrandsPage from './pages/Brands/BrandsPage';
import CattleShowPage from './pages/CattleShows/CattleShowPage';
import AuctionsPage from './pages/Auctions/AuctionsPage';
import Promotions from './pages/Promotions/Promotions';
import AboutPage from './pages/About/AboutPage';
import ContactPage from './pages/Contact/ContactPage';
import CardDetailsPage from './pages/CardDetails/CardDetailsPage';
import SeasonsPage from './pages/Seasons/SeasonsPage';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="brands" element={<BrandsPage />} />
          <Route path="cattle-shows" element={<CattleShowPage />} />
          <Route path="auctions" element={<AuctionsPage />} />
          <Route path="promotions" element={<Promotions />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="card-details" element={<CardDetailsPage />} />
          <Route path="season" element={<SeasonsPage />} />
        </Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
    </>
  );
}

export default App;
