import { Helmet } from "react-helmet"
import HeroSection from "../../components/HeroSection/HeroSection"
import MobileApp from "../Home/MobileApp"
import Offers from "../Home/Offers"
import AboutSection from "./AboutSection"

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About us - Dalfa Pak</title>
      </Helmet>
      <HeroSection subheading="Discover more" heading="About Dalfa" />
      <AboutSection />
      <Offers />
      <MobileApp />
    </>
  )
}
export default AboutPage