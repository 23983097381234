import { useCallback, useEffect, useState } from "react";

function Timer({ targetDate }) {
    const [timeLeft, setTimeLeft] = useState({});

    // Memoize the function so that it doesn't change on every render
    const calculateTimeLeft = useCallback(() => {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            return {};
        }
    }, [targetDate]); // depend on targetDate

    useEffect(() => {
        setTimeLeft(calculateTimeLeft());
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate, calculateTimeLeft]); // Now calculateTimeLeft is memoized

    return (
        <div className="timer d-flex align-items-center justify-content-center gap-md-5 gap-3">
            <div className="text-center">
                <p className="m-0 display-4 no_lineheight text_ui_dark fstech">{timeLeft.days <= 9 ? '0' + timeLeft.days : timeLeft.days}</p>
                <p className="m-0 fs_5 no_lineheight text-dark-emphasis text-uppercase fw-medium">Days</p>
            </div>
            <div className="text-center">
                <p className="m-0 display-4 no_lineheight text_ui_dark fstech">{timeLeft.hours <= 9 ? '0' + timeLeft.hours : timeLeft.hours}</p>
                <p className="m-0 fs_5 no_lineheight text-dark-emphasis text-uppercase fw-medium">Hours</p>
            </div>
            <div className="text-center">
                <p className="m-0 display-4 no_lineheight text_ui_dark fstech">{timeLeft.minutes <= 9 ? '0' + timeLeft.minutes : timeLeft.minutes}</p>
                <p className="m-0 fs_5 no_lineheight text-dark-emphasis text-uppercase fw-medium">Minutes</p>
            </div>
            <div className="text-center">
                <p className="m-0 display-4 no_lineheight text_primary fstech">{timeLeft.seconds <= 9 ? '0' + timeLeft.seconds : timeLeft.seconds}</p>
                <p className="m-0 fs_5 no_lineheight text-dark-emphasis text-uppercase fw-medium">Seconds</p>
            </div>
        </div>
    );
}

export default Timer;
