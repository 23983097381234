import BrandCards from "../../components/BrandCards/BrandCards"

function BrandCardsList() {
  return (
    <>
      <section className="my-5">
        <div className="container-lg wrapper">
          <div className="row my-3">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
          </div>
        </div>
      </section>
    </>
  )
}
export default BrandCardsList