import { Helmet } from 'react-helmet'
import HeroSection from '../../components/HeroSection/HeroSection'
import { brandsBackground } from '../../Images'
import Gallery from '../Home/Gallery'
import MobileApp from '../Home/MobileApp'
import BrandCardsList from './BrandCardsList'

function BrandsPage() {
  return (
    <>
        <Helmet>
          <title>Brands - Dalfa Pak</title>
        </Helmet>
        <HeroSection subheading="Explore the top-rated" heading="Dalfa brands" text="Explore all our cattle brands from Pakistan and find the perfect one to make your ideal deal!" image={brandsBackground} />
        <BrandCardsList />
        <Gallery />
        <MobileApp />
    </>
  )
}
export default BrandsPage