import DisplayCard from "../../components/DisplayCard/DisplayCard"
import { animal4, animal6, newArrival3 } from "../../Images"

function RelatedCards() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg">
                <h2 className="fw-semibold text_ui_dark text-center">More from <span className="text_primary">Danish Cattle</span></h2>
                <div className="row my-4">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Fakhar e Supreme" cattle="Danish Cattle" image={newArrival3} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Chaand" cattle="Danish Cattle" image={animal4} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Fakhar e Azam" cattle="Danish Cattle" image={animal6} />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default RelatedCards