import { Link } from "react-router-dom"

function NotFound() {
  return (
    <>
        <div className="my-4">
            <h1 className="text-center fs-3">Page Not Found</h1>
            <p className="text-center">Go back to home the page isn't foudn here! <Link to={'/'}>Back to home</Link></p>
        </div>
    </>
  )
}
export default NotFound