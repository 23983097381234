import './CattleShowCard.css'
import { Link } from "react-router-dom"

function CattleShowCard(props) {
    return (
        <>
            <Link to="/season" className="showsCard p-2 shadow_hover rounded-4 d-flex gap-2 text-decoration-none">
                <div className="showsCardImage">
                    <img src={props.image} alt="" />
                </div>
                <div className="showsCardDetails w-100 py-2">
                    <div className="d-flex align-items-center gap-1 justify-content-between">
                        <p className="fs_7 m-0 text_ui_dark">{props.season}</p>
                        <div className={`showsCardbadge ${props.status === 'upcoming' ? 'bg_primary' : 'bg_secondary'} text_ui_light d-inline-flex align-items-center gap-1 p-1 px-2 rounded-pill fs_xs text-capitalize`}>{props.status}</div>
                    </div>
                    <h3 className="fs_5 fw-semibold my-1 text_dark">{props.title}</h3>
                    <p className="fs_7 m-0 text-dark-emphasis">{props.location}</p>
                    <div className="text-end">
                        <p className={`py-1 d-inline-flex align-items-center ${props.status === 'upcoming' ? 'text_primary' : 'text_secondary'} fs_6 fw-medium m-0`}>
                            <span>{props.status === 'upcoming' ? 'Get Tickets' : 'Explore Event'}</span>
                            <i className="bx bx-right-arrow-alt"></i>
                        </p>
                    </div>
                </div>
            </Link>
        </>
    )
}
export default CattleShowCard