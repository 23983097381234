import { applestore, googleplay, mobileApp } from "../../Images"

function MobileApp() {
  return (
    <>
        <section className="mt-5 mobileAppSection">
            <div className="container-lg wrapper">
                <div className="row pt-5">
                    <div className="col-md-6 col-12 align-self-center">
                        {/* <h2 className="fw-semibold text_ui_dark fs-1">Download our mobile application</h2> */}
                        <h2 className="fw-semibold text_ui_dark fs-1">Apps are more easy and convenient</h2>
                        <p className="text_ui_dark">Our mobile application is live now! Download it now from the App Store or Play Store to buy or sell cattle, explore promotions, join auctions, and stay updated on exciting cattle shows.</p>
                        <div className="d-flex align-items-center flex-wrap gap-2 mb-4">
                            <a href="https://play.google.com/store/apps/details?id=com.dalfa&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" className="d-inline-flex">
                                <img src={googleplay} width={150} alt="" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.dalfa&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" className="d-inline-flex">
                                <img src={applestore} width={150} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 text-center">
                        <img src={mobileApp} width={400} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default MobileApp