import { Helmet } from "react-helmet"
import HeroSection from "../../components/HeroSection/HeroSection"
import { auctionsHeader } from "../../Images"
import AuctionList from "./AuctionList"

function AuctionsPage() {
  return (
    <>
      <Helmet>
        <title>Auctions - Dalfa Pak</title>
      </Helmet>
      <HeroSection heading="Auctions" image={auctionsHeader} />
      <AuctionList />
    </>
  )
}
export default AuctionsPage