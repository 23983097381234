import { Helmet } from "react-helmet"
import HeroSection from "../../components/HeroSection/HeroSection"
import { auctionsHeader } from "../../Images"
import AuctionList from "../Auctions/AuctionList"

function Promotions() {
    return (
        <>
            <Helmet>
                <title>Promotions - Dalfa Pak</title>
            </Helmet>
            <HeroSection heading="Promotions" image={auctionsHeader} />
            <AuctionList />
        </>
    )
}
export default Promotions