function Hero() {
  return (
    <>
        <header>
            <div id="headerSlides" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#headerSlides" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#headerSlides" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#headerSlides" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item carouselSlide1 active">
                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                            <div className="container-lg wrapper">
                                <p className="text-center text_light text-uppercase fw-medium m-0 fs-5">Welcome to</p>
                                <h1 className="text_light_primary display-5 text-center m-0 fstech">dalfa pak </h1>
                                <p className="text-center text_light m-0 fs_4">Dairy, Agriculture, Livestock, Fisheries & Advanced Technology</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item carouselSlide2"></div>
                    <div className="carousel-item carouselSlide3"></div>
                </div>
            </div>
        </header>
    </>
  )
}
export default Hero