import { Link } from 'react-router-dom'
import './DisplayCard.css'

function DisplayCard(props) {
  return (
    <>
        <div className="displayCard shadow rounded-4">
            <div className="displayCardImage">
                <img src={props.image} alt="" />
                {props.badge === "new" ? (
                    <div className="newBadge rounded-pill p-1 px-3 text_ui_light m-2 fs_7 fw-medium d-inline-flex align-items-center justify-content-center">
                        <i className='bx bxs-hot small'></i>
                        <span className="text-capitalize">{props.badge}</span>
                    </div>
                ) : ''}
            </div>
            <div className="displayCardDetails p-3">
                <h3 className="fw-semibold fs_5 text_ui_dark mb-1">{props.title}</h3>
                <p className="mb-2 fs_7 text_ui_dark"><span className="fw-medium">Cattle</span>: {props.cattle}</p>
                <Link to={'/card-details'} className="btn_primary w-100 p-2 px-3 d-flex align-items-center justify-content-center gap-1">
                    <span className="fs_6">See Details</span>
                    <i className="bx bx-right-arrow-alt"></i>
                </Link>
            </div>
        </div>
    </>
  )
}
export default DisplayCard