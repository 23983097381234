import { Outlet } from "react-router-dom"
import Topbar from "../Topbar/Topbar"
import AppNavbar from "../AppNavbar/AppNavbar"
import AppFooter from "../AppFooter/AppFooter"

function MainLayout() {
  return (
    <>
      <Topbar />
      <AppNavbar />
      <Outlet />
      <AppFooter />
    </>
  )
}
export default MainLayout