import { aboutImage } from "../../Images"

function AboutSection() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg wrapper">
                <div className="row align-items-center my-4">
                    <div className="col-lg-7 col-md-6 col-12 my-3">
                        <h2 className="fw-semibold text_ui_dark display-5">Who We Are: <span className="text_primary text-uppercase">Dalfa</span></h2>
                        <p className="text_ui_dark mb-1">Dalfa is Pakistan’s largest farm, specializing in dairy, agriculture, livestock, and fishery. We focuses on sustainable farming practices and uses modern technology like windmills and solar energy panels to reduce environmental impact.</p>
                        <p className="text_ui_dark mb-1">We are committed to providing high-quality products while promoting clean energy and supporting the growth of the agricultural sector, ensuring sustainability and creating opportunities for future generations.</p>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 my-3 text-center">
                        <img src={aboutImage} width={450} height="auto" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default AboutSection