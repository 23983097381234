import { Helmet } from "react-helmet"
import MobileApp from "../Home/MobileApp"
import CardDetails from "./CardDetails"
import RelatedCards from "./RelatedCards"

function CardDetailsPage() {
  return (
    <>
      <Helmet>
        <title>Details - Dalfa Pak</title>
      </Helmet>
      <CardDetails />
      <RelatedCards />
      <MobileApp />
    </>
  )
}
export default CardDetailsPage