import './AppNavbar.css'
import { logo, logo_landscape } from '../../Images'
import { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

function AppNavbar() {

    const [show, setShow] = useState(false);

    const showSidebar = () => {
        console.log('ok')
        setShow(true);
    }
    
    const hideSidebar = () => {
        setShow(false);
    }

    // scroll effect 
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled >= 44) {
                setScrolled(true);
            } else {
                setScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        window.addEventListener('load', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('load', handleScroll)
        }
    }, [])

    const [windowWidth, setWindowWidth] = useState(false)

    useEffect(() => {
        const handleLogo = () => {
            const windowSize = window.innerWidth;
            if (windowSize <= 991) {
                setWindowWidth(true)
            } else {
                setWindowWidth(false)
            }
        }
        window.addEventListener('load', handleLogo)
        window.addEventListener('resize', handleLogo)
        return () => {
            window.removeEventListener('load', handleLogo)
            window.removeEventListener('resize', handleLogo)
        }
    }, [])
    
    

  return (
    <>
        <section className={`navbarSticky ${scrolled ? 'inMotion' : ''}`}>
            <nav className="navbar navbar-expand-lg">
                <div className="container-lg wrapper justify-content-lg-center justify-content-between gap-2 px-0">
                    <div className='text-start flex-lg-grow-0 flex-grow-1'>
                        <button className="navbar-toggler border-0 shadow-none" onClick={showSidebar} type="button">
                            <span className="navbar-toggler-icon navText"></span>
                        </button>
                    </div>
                    <ul className="navbar-nav gap-2 d-lg-flex d-none">
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={'/'}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={'/brands'}>Our Brands</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={"/cattle-shows"}>Cattle Shows</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={"/auctions"}>Auctions</NavLink>
                        </li>
                    </ul>
                    <div className='text-center flex-lg-grow-0 flex-grow-1'>
                        <Link className="navbar-brand m-0" to={"/"}>
                            {windowWidth ? (
                                <img src={logo_landscape} width={160} height='auto' alt="dalfa pak logo" />
                            ) : (
                                <img src={logo} width={130} height='auto' alt="dalfa pak logo" />
                            )}
                        </Link>
                    </div>
                    <ul className="navbar-nav gap-2 d-lg-flex d-none">
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={"/promotions"}>Promotions</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={"/about"}>About us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link navText" to={"/contact"}>Contact</NavLink>
                        </li>
                    </ul>
                    <div className='text-end flex-lg-grow-0 flex-grow-1'>
                        <Link to={"/"} className="d-inline-flex align-items-center justify-content-center btn_outline_primary rounded-pill px-4 navText">Login</Link>
                    </div>
                </div>
            </nav>
        </section>

        {show ? (
            <>
                <div className={`offcanvas offcanvas-start ${show ? 'show' : ''}`} tabIndex="-1" id="sideNavbar">
                    <div className="text-center border-bottom p-3">
                        <div className="text-end">
                            <button type="button" className="btn-close shadow-none" onClick={hideSidebar}></button>
                        </div>
                        <Link to="/" onClick={hideSidebar}>
                            <img src={logo} width={120} height={'auto'} alt="dalfa pak logo" />
                        </Link>
                    </div>
                    <div className="offcanvas-body h-100 overflow-y-auto">
                        <ul className="sideNavbarList m-0">
                            <li>
                                <NavLink to="/" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>Home</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/brands" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>Our Brands</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/cattle-shows" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>Cattle Shows</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/auctions" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>Auctions</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/promotions" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>Promotions</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>About us</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" className="sideNavbarListLink" onClick={hideSidebar}>
                                    <span>Contact</span>
                                    <i className="bx bx-right-arrow-alt"></i>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="position-sticky bottom-0 end-0 start-0 p-3">
                        <button className="btn_outline_primary w-100 rounded-pill p-2" onClick={hideSidebar}>Login</button>
                        <p className="m-0 text-center text_ui_dark fs_6 mt-1">Don't have an account? <Link to="/" className="text_primary" onClick={hideSidebar}>Create one</Link></p>
                    </div>
                </div>
                <div className="backdropEffect"></div>
            </>
        ) : ('')}
    </>
  )
}
export default AppNavbar