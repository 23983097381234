function Topbar() {
  return (
    <>
        <div className="topbar bg_primary py-2">
            <div className="container-lg wrapper px-sm-2 px-1">
                <div className="d-flex align-items-center gap-2 justify-content-between">
                    <a href="mailto:info@dalfapak.com" target="_blank" rel="noopener noreferrer" className="d-inline-flex align-items-center gap-1 text_ui_light text-decoration-none">
                        <i className='bx bx-envelope'></i>
                        <span className="fs_7">info@dalfapak.com</span>
                    </a>
                    <div className="d-inline-flex align-items-center gap-lg-2 gap-1 justify-content-between">
                        <a href="https://www.facebook.com/dalfapak" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link d-inline-flex align-items-center justify-content-center text-decoration-none">
                            <i className='bx bxl-facebook'></i>
                        </a>
                        <a href="https://www.instagram.com/dalfapakistan/" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link d-inline-flex align-items-center justify-content-center text-decoration-none">
                            <i className='bx bxl-instagram'></i>
                        </a>
                        <a href="https://www.youtube.com/@DalfaPakOfficial" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link d-inline-flex align-items-center justify-content-center text-decoration-none">
                            <i className='bx bxl-youtube'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
export default Topbar