import { brandsBackground } from "../../Images";

function HeroSection(props) {
    const headerStyles = {
        height: '450px',
        width: 'auto',
        background: `linear-gradient(180deg, #0f0f0f15, #00000073), url(${props.image ? props.image : brandsBackground}) center fixed`,
        backgroundSize: 'cover',
    };
      
    return (
        <>
            <header className="py-5 d-flex align-items-center justify-content-center" style={headerStyles}>
                <div className="container-lg wrapper">
                    {props.subheading ? <p className="text_ui_light fs-5 text-uppercase fw-medium m-0 text-center">{props.subheading}</p> : '' }
                    {props.heading ? <h1 className="fstech text-center display-5 text_light_primary">{props.heading}</h1> : ''}
                    {props.text ? <p className="text_ui_light m-0 text-center">{props.text}</p> : ''}
                </div>
            </header>
        </>
    )
}
export default HeroSection