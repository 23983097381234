import DisplayCard from "../../components/DisplayCard/DisplayCard"
import { animal4, animal5, animal6, newArrival1, newArrival2, newArrival3 } from "../../Images"

function AuctionList() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg wrapper">
                <div className="row my-3">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Bhola" cattle="Al-Bashir Cattle" image={newArrival1} badge="new" />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Laadla" cattle="Bilwani Cattle Goat Farm" image={newArrival2} badge="new" />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Fakhar e Supreme" cattle="Supreme Cattle Farm" image={newArrival3} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Shera" cattle="Al-Bashir Cattle" image={animal4} />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Shaandaar" cattle="Supreme Cattle" image={animal5} badge="new" />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3">
                        <DisplayCard title="Chaand" cattle="Supreme Cattle Farm" image={animal6} />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
export default AuctionList