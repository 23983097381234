import './AppFooter.css'
import { applestore, googleplay, logo } from "../../Images"
import { Link } from 'react-router-dom'

function AppFooter() {
  return (
    <>
        <footer className="py-4">
            <div className="container-lg wrapper">
                <div className="row justify-content-between align-items-center border-bottom border-dark pb-2">
                    <div className="col-md-auto col-12 my-2">
                        <p className="fw-medium text_light mb-2">Get in touch</p>
                        <div className="d-inline-flex align-items-center gap-2 justify-content-between">
                            <Link to="https://www.facebook.com/dalfapak" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link bg_facebook d-inline-flex align-items-center justify-content-center text-decoration-none">
                                <i className='bx bxl-facebook'></i>
                            </Link>
                            <Link to="https://www.instagram.com/dalfapakistan/" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link bg_instagram d-inline-flex align-items-center justify-content-center text-decoration-none">
                                <i className='bx bxl-instagram'></i>
                            </Link>
                            <Link to="https://www.youtube.com/@DalfaPakOfficial" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link bg_youtube d-inline-flex align-items-center justify-content-center text-decoration-none">
                                <i className='bx bxl-youtube'></i>
                            </Link>
                            <Link to="mailto:info@dalfapak.com" target="_blank" rel="noopener noreferrer" className="text_ui_light social_link bg_gmail d-inline-flex align-items-center justify-content-center text-decoration-none">
                                <i className='bx bxl-gmail'></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-auto col-12 my-2">
                        <p className="fw-medium text_light mb-2">Subscribe Newsletter</p>
                        <div className="newsletterContainer error">
                            <input type="text" className="form-control shadow-none border-0 fs_7 py-0" placeholder="Enter your email" />
                            <button className="btn_primary fs_6 d-inline-flex align-items-center rounded-pill">Send</button>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-2">
                    <div className="col-lg-4 col-md-4 col-6 col-12 my-2">
                        <Link to="/" className="text-decoration-none">
                            <img src={logo} width="140" height="auto" alt="" />
                        </Link>
                        <p className="text_light my-2 text-white-75 fs_6">We are leading farm in Pakistan serving Dairy, Agriculture, Livestock, Fisheries & Advanced Technology in Pakistan</p>
                        <Link to="https://play.google.com/store/apps/details?id=com.dalfa&pcampaignid=web_share" target="_blank" rel="noopener noreferrer" className="d-inline-flex align-items-center">
                            <img src={googleplay} width="120" alt="" />
                            <img src={applestore} width="120" alt="" />
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 col-12 my-2">
                        <p className="fw-medium text_light mb-2">Quick Links</p>
                        <ul className="list-unstyled">
                            <li className="my-2"><Link to="/" className="text-decoration-none footer_link fs_6">Home</Link></li>
                            <li className="my-2"><Link to="/brands" className="text-decoration-none footer_link fs_6">Our Brands</Link></li>
                            <li className="my-2"><Link to="/auctions" className="text-decoration-none footer_link fs_6">Auctions</Link></li>
                            <li className="my-2"><Link to="/cattle-shows" className="text-decoration-none footer_link fs_6">Cattle Shows</Link></li>
                            <li className="my-2"><Link to="/promotions" className="text-decoration-none footer_link fs_6">Promotions</Link></li>
                            <li className="my-2"><Link to="/" className="text-decoration-none footer_link fs_6">Login your Account</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 col-12 my-2">
                        <p className="fw-medium text_light mb-2">Company</p>
                        <ul className="list-unstyled">
                            <li className="my-2"><Link to="/contact" className="text-decoration-none footer_link fs_6">Contact</Link></li>
                            <li className="my-2"><Link to="/about" className="text-decoration-none footer_link fs_6">About us</Link></li>
                            <li className="my-2 text-white-75 fs_6"><span className="fw-semibold">Phone: </span> <Link to="tel:+92 312128236" target="_blank" rel="noopener noreferrer" className="text-decoration-none footer_link fs_6">+92 312128236</Link></li>
                            <li className="my-2 text-white-75 fs_6"><span className="fw-semibold">Email: </span> <Link to="mailto:info@dalfapak.com" target="_blank" rel="noopener noreferrer" className="text-decoration-none footer_link fs_6">info@dalfapak.com</Link></li>
                            <li className="my-2 text-white-75 fs_6"><span className="fw-semibold">Address: </span> <Link to="/" rel="noopener noreferrer" target="_blank" className="text-decoration-none footer_link fs_6">Office No. # 402 Business Forum Building Main Khalid Bin Waleed Road Karachi.</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="border-top border-dark pt-4">
                    <p className="text-white-50 text-center m-0 fs_6">Copyright &copy; All rights reserved. <Link to="/" className="text-decoration-none footer_link">Dalfapak.com</Link></p>
                </div>
            </div>
        </footer>
    </>
  )
}
export default AppFooter
