import BrandCards from "../../components/BrandCards/BrandCards"

function Brands() {
  return (
    <>
        <section className="my-5">
            <div className="container-lg wrapper">
                <h2 className="text-center fw-semibold text_ui_dark display-5">Dalfa <span className="text_primary">Brands</span></h2>
                <div className="row my-3">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-3"><BrandCards /></div>
                </div>
            </div>
        </section>
    </>
  )
}
export default Brands