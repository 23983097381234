import { Helmet } from "react-helmet"
import HeroSection from "../../components/HeroSection/HeroSection"
import MobileApp from "../Home/MobileApp"
import Offers from "../Home/Offers"
import ContactInfo from "./ContactInfo"

function ContactPage() {
  return (
    <>
        <Helmet>
          <title>Contact - Dalfa Pak</title>
        </Helmet>
        <HeroSection subheading="Need assistance?" heading="Contact Dalfa" />
        <ContactInfo />
        <Offers />
        <MobileApp />
    </>
  )
}
export default ContactPage